import styles from './chat-container.css';
import { DEFAULT_STYLE_PROPS, getStyleProps, toPixel } from './utils';

const ASSETS_BASE_URL = `${process.env.CDN_PATH}/assets`;

export default class ChatContainer {
  constructor({ theme, imageURL, showOnInit = true, buttonStyles = {} }) {
    this.theme = theme;
    this.imageURL = imageURL;
    this.open = false;
    this.initialize({ showOnInit, buttonStyles });
  }

  resetStylingConfig = (params) => {
    if (params?.position) {
      this.buttonContainer.style.left = 'unset';
      this.buttonContainer.style.right = 'unset';
      this.messageContainer.style.left = 'unset';
      this.messageContainer.style.right = 'unset';
    }
  };

  setStylingConfig = (params) => {
    const updatedProps = getStyleProps(params, this.styleConfig);

    const isMobileView = window.screen.width <= 600;
    const windowInnerHeight = window?.innerHeight;
    const horizontalPos =
      (updatedProps.position === 'left' && 'left') || 'right';

    this.buttonContainer.style.bottom = toPixel(updatedProps.verticalSpacing);
    this.messageContainer.style.bottom = toPixel(updatedProps.verticalSpacing);
    this.buttonContainer.style[horizontalPos] = toPixel(
      updatedProps.horizontalSpacing
    );

    if (!isMobileView) {
      this.messageContainer.style[horizontalPos] = toPixel(
        updatedProps.horizontalSpacing
      );

      // If screen height is less than widget container height, set
      // container position bottom to 15px..
      if (windowInnerHeight < 600) {
        this.messageContainer.style.bottom = '15px';
      } else {
        // Adjusting message container height to stay within the window..
        const calContainerHeight = updatedProps.verticalSpacing + 10;
        this.messageContainer.style.height = `calc(100% - ${calContainerHeight}px)`;
      }
    }

    this.buttonContainer.style.width = toPixel(updatedProps.size);
    this.buttonContainer.style.height = toPixel(updatedProps.size);

    this.styleConfig = { ...updatedProps };
  };

  initialize = ({ showOnInit, buttonStyles }) => {
    // Main container.........................
    const container = document.createElement('div');
    container.classList.add('akin-ai-chat');
    document.body.appendChild(container);

    // Chat bubble button container......................
    // Stylesheet take some time to reflect on initial load of the widget button
    // Hence to avoid glitches, styles are directly applied to button container element.
    this.buttonContainer = document.createElement('div');
    this.buttonContainer.id = 'akin-chat-button';
    this.buttonContainer.style.position = 'fixed';
    this.buttonContainer.style.width = toPixel(DEFAULT_STYLE_PROPS.size);
    this.buttonContainer.style.height = toPixel(DEFAULT_STYLE_PROPS.size);
    this.buttonContainer.style.borderRadius = '50%';
    this.buttonContainer.style.bottom = toPixel(
      DEFAULT_STYLE_PROPS.verticalSpacing
    );
    this.buttonContainer.style.overflow = 'hidden';
    this.buttonContainer.style.backgroundColor = this.theme;
    this.buttonContainer.style.display = (!showOnInit && 'none') || 'block';
    this.buttonContainer.classList.add(styles.buttonContainer);

    // Chat bubble icon......................
    const chatIcon = document.createElement('img');
    chatIcon.src = this.imageURL || `${ASSETS_BASE_URL}/chat.svg`;
    chatIcon.alt = 'D3x chat widget';
    // chatIcon.title = 'D3x chat widget';
    chatIcon.classList.add(this.imageURL ? styles.icon : styles.defaultIcon);
    this.chatIcon = chatIcon;

    // Appending chat icon and close icon chat button container(chat bubble).
    this.buttonContainer.appendChild(this.chatIcon);
    this.buttonContainer.addEventListener('click', this.toggleOpen.bind(this));

    // Iframe message container...
    this.messageContainer = document.createElement('div');
    this.messageContainer.id = 'akin-chat-container';
    this.messageContainer.classList.add(styles.hidden, styles.messageContainer);

    // handle dynamic styling config for chat button and container.......
    this.setStylingConfig({ ...buttonStyles });

    // Appending Iframe message container and button container to main container..
    container.appendChild(this.messageContainer);
    container.appendChild(this.buttonContainer);
  };

  toggleOpen = () => {
    this.open = !this.open;
    if (this.open) {
      this.chatIcon.classList.add(styles.hidden);
      this.messageContainer.classList.remove(styles.hidden);
      // Hides the chat bubble
      this.buttonContainer.style.display = 'none';
    } else {
      this.chatIcon.classList.remove(styles.hidden);
      this.messageContainer.classList.add(styles.hidden);
      // Shows the chat bubble
      this.buttonContainer.style.display = 'unset';
    }
  };

  hideChat = () => {
    if (!this.messageContainer || !this.buttonContainer) return;
    this.messageContainer.style.display = 'none';
    this.buttonContainer.style.display = 'none';
    if (this.open) this.toggleOpen();
  };

  showChat = () => {
    if (!this.messageContainer || !this.buttonContainer) return;
    this.messageContainer.style.display = 'unset';
    this.buttonContainer.style.display = 'unset';
  };
}
