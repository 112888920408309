/* eslint-disable no-param-reassign */
import ChatContainer from './chatContainer';
import ChatWidgetComponent from './zoid/component';
import storageAPI from './lib/webStorageAPI';
import cs, { CHAT_EVENTS } from './lib/chatSocketAPI';

function extendObject(a, b) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in b) {
    // eslint-disable-next-line no-param-reassign
    if (Object.prototype.hasOwnProperty.call(b, key)) a[key] = b[key];
  }
  return a;
}

const supportedAPI = ['init', 'show', 'hide', 'destroy', 'custom_styles'];

class WidgetAPI {
  constructor() {
    this.injectCSS();
    this.configurations = {};
    this.chatContainer = null;
    this.componentWidget = null;
  }

  injectCSS = () => {
    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';

    if (process.env.ENV !== 'development') {
      link.href = `${process.env.CDN_PATH}/widget.css`;
    }

    const { head } = document;
    head.appendChild(link);
  };

  initChatSocket = ({ referrer, sessionId, initParams = {} }) => {
    cs.connect({
      referrer,
      conversation_id: sessionId,
      initParams,
    });

    cs.socket.io.on(CHAT_EVENTS.RECONNECT, () => {
      cs.handshake({
        referrer,
        conversation_id: sessionId,
      });
      this.updateIframeProps({ chatEvent: 'reconnect' });
    });

    cs.on(CHAT_EVENTS.CONNECT_ERROR, (data) => {
      if (data?.type && data.type === 'TransportError') {
        this.updateIframeProps({ chatEvent: 'network_error', eventData: data });
        return;
      }

      this.updateIframeProps({ chatEvent: 'connect_error', eventData: data });
    });

    cs.on(CHAT_EVENTS.VISITOR_HISTORY, (data = {}) => {
      this.updateIframeProps({
        chatEvent: 'init',
        eventData: {
          chatHistory: data?.conversation,
          aiDisabled: data?.ai_disabled,
        },
      });
    });

    cs.on(CHAT_EVENTS.VISITOR_META_EVENT, (data = {}) => {
      this.updateIframeProps({
        chatEvent: 'visitor_meta_event',
        eventData: { ...data.conversationUpdate },
      });
    });

    cs.on(CHAT_EVENTS.AI_RESPONSE, (message = {}) => {
      this.updateIframeProps({
        chatEvent: 'ai_response',
        eventData: { ...message },
      });
    });

    cs.on(CHAT_EVENTS.PLATFORM_INPUT, (message = {}) => {
      this.updateIframeProps({
        chatEvent: 'platform_message',
        eventData: { ...message },
      });
    });

    cs.on(CHAT_EVENTS.VISITOR_ERROR, (data = {}) => {
      this.updateIframeProps({
        chatEvent: 'visitor_error',
        eventData: { ...data },
      });
    });

    cs.on(CHAT_EVENTS.AI_RESPONSE_STREAM, (data = {}) => {
      this.updateIframeProps({
        chatEvent: 'ai_response_stream',
        eventData: { ...data },
      });
    });
  };

  init = (config) => {
    this.configurations = extendObject(this.configurations, config);
    const props = this.getIframeProps(this.configurations);

    if (this.chatContainer) {
      this.updateIframeProps(props);
      return;
    }

    this.chatContainer = new ChatContainer(props);
    this.mountIframe(this.chatContainer.messageContainer.id);
  };

  upsertStorageItem = (key, item) => {
    storageAPI.setItem(key, item);
  };

  getStorageItem = (key) => {
    const item = storageAPI.getItem(key);
    return item;
  };

  getIframeProps = (props = {}) => ({
    showOnInit: props.showOnInit,
    theme: props?.theme || '#000000',
    language: props?.language || 'en',
    title: props?.title || 'AI Chat',
    imageURL: props?.imageURL,
    spaceId: props?.spaceId,
    referrer: window.location.href,
    initChatSocket: this.initChatSocket,
    getStorageItem: this.getStorageItem,
    upsertStorageItem: this.upsertStorageItem,
    chatEvent: null,
    eventData: null,
    sendMessage: cs.sendMessage,
    chatSocketHandshake: cs.handshake,
    customParams: {
      agentId: props?.agentId,
      agentName: props?.agentName,
      offeringIds: props?.offeringIds,
      countryCode: props?.countryCode,
    },
    buttonStyles: props?.buttonStyles,
  });

  mountIframe = (elementId) => {
    const props = this.getIframeProps(this.configurations);
    this.componentWidget = ChatWidgetComponent({
      ...props,
      closeChat: this.chatContainer.toggleOpen,
    });
    this.componentWidget.render(`#${elementId}`);
  };

  updateIframeProps = (props) => {
    this.componentWidget.updateProps(props);
  };

  handler = (api, params) => {
    if (!api) throw Error('API method required');

    api = api.toLowerCase();

    if (supportedAPI.indexOf(api) === -1)
      throw Error(`Method ${api} is not supported`);

    switch (api) {
      case 'init':
        this.init(params);
        break;

      case 'hide':
        this.chatContainer.hideChat();
        break;

      case 'show':
        this.chatContainer.showChat();
        break;

      case 'destroy':
        if (this.chatContainer?.messageContainer)
          this.chatContainer.messageContainer.remove();

        if (this.chatContainer?.buttonContainer)
          this.chatContainer.buttonContainer.remove();

        this.chatContainer = null;
        cs.disconnect();
        break;

      case 'custom_styles':
        this.chatContainer.resetStylingConfig(params);
        this.chatContainer.setStylingConfig(params);
        break;

      default:
        // eslint-disable-next-line no-console
        console.warn(`No handler defined for ${api}`);
    }
  };
}

function app(window) {
  const globalObjectId =
    window?.['akin-chat-widget'] || window?.['d3x-chat-widget'] || 'cw';

  const globalObject = window[globalObjectId];
  const api = new WidgetAPI();

  const queue = globalObject.q;
  if (queue) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < queue.length; i++) {
      api.handler(queue[i][0], queue[i][1]);
    }
  }

  window[globalObjectId] = api.handler;
}

app(window);
